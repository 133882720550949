<template>
  <div class="regin">
    <div class="title">{{currentLang.basicInfo}}</div>
    <div class="line">
      <span class="lable">{{currentLang.username}}*</span>
      <input type="text" name="" id="" ref="userCode" />
    </div>
    <div class="line">
      <span class="lable">{{currentLang.email}}*</span>
      <input type="text" name="" id="email" ref="email" />
    </div>
    <div class="line">
      <span class="lable">{{currentLang.password}}*</span>
      <input type="password" name="" id="pwd" ref="pwd" />
    </div>
    <div class="line">
      <span class="lable">{{currentLang.passwordAgain}}*</span>
      <input class="superIndent" type="password" name="" id="pwd2" ref="pwd2" />
    </div>
    <div class="line">
      <span class="lable">{{currentLang.gender}}</span>
      <van-radio-group class="sexGroup" v-model="sex" ref="sex" icon-size="16px" checked-color="#AB856F"
        direction="horizontal">
        <van-radio name="1">{{currentLang.male}}</van-radio>
        <van-radio name="2">{{currentLang.female}}</van-radio>
      </van-radio-group>
    </div>
    <div class="line" @click="birthdayActive = true">
      <span class="lable">{{currentLang.birthday}}</span>
      <span class="suojin">{{ birthdayText }}</span>
    </div>
    <!--<div class="line" @click="nationActive = true">
      <span class="lable">{{currentLang.country}}</span> <span class="suojin">{{ nationText }}</span>
    </div>-->
    <div class="line">
      <span class="lable">{{currentLang.country}}*</span>
      <input type="text" name="" id="" ref="nationText" :placehodler="currentLang.chooseCountry" />
    </div>
    <div class="line">
      <span class="lable">{{currentLang.serviceCondition}}</span><span class="greenColor suojin">
        <van-radio-group v-model="radio" icon-size="16px" checked-color="#62c3ab">
          <van-radio name="1" label-disabled @click="details">
            {{currentLang.agree}}<i>{{currentLang.serviceCondition}}</i></van-radio>
        </van-radio-group>
      </span>
    </div>
    <div class="bottomBtn" @click="regin">{{currentLang.register}}</div>
    <!--生日选择选择-->
    <van-popup v-model="birthdayActive" position="bottom" close-on-click-overlay>
      <van-datetime-picker @confirm="selBirthDay" @cancel="birthdayActive = false" v-model="birthday" type="date"
        :title="currentLang.chooseDate" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <!--国家选择-->
    <!--<van-popup v-model="nationActive" position="bottom" close-on-click-overlay>
      <van-cascader v-model="nationText" :title="currentLang.chooseCountry" :options="nationOptions"
        @close="nationActive = false" @finish="onFinish" />
    </van-popup>-->
  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        sex: "1",
        birthday: new Date(2001, 1, 15),
        maxDate: new Date(),
        minDate: new Date(1900, 1, 1),
        birthdayActive: false,
        birthdayText: "choose your birthday...",
        nationActive: false,
        nationText: "choose your region...",
        nationOptions: [{
            text: "China",
            value: "021",
          },
          {
            text: "USA",
            value: "030",
            // children: [{ text: '南京市', value: '320100' }],
          },
        ],
        radio: '1',
        languageLib: {
          lang_1: {
            basicInfo: 'basic info',
            username: 'username',
            email: 'email',
            password: 'password',
            passwordAgain: 'password again',
            gender: 'gender',
            male: 'male',
            female: 'female',
            birthday: 'birthday',
            country: 'country',
            serviceCondition: 'service condition',
            agree: 'agree',
            register: 'register',
            chooseDate: 'choose date',
            chooseCountry: 'choose country',
            chooseBirthday: 'choose your birthday...',
            chooseRegion: 'choose your region...',
            china: 'China',
            usa: 'USA',
            tipUsername: 'Please Input username',
            tipEmail: 'Please Input Email',
            tipEmailNotPass: 'check email failed',
            tipPassword: 'Please Input Password',
            tipPwd2: 'Please Input Same Password',
            minPasswordLength: 'min Password Length 6'
          },
          lang_2: {
            basicInfo: '基础信息',
            username: '用户名',
            email: '邮箱',
            password: '密码',
            passwordAgain: '再次输入密码',
            gender: '性别',
            male: '男',
            female: '女',
            birthday: '生日',
            country: '国家',
            serviceCondition: '服务条款',
            agree: '同意',
            register: '注册',
            chooseDate: '选择年月日',
            chooseCountry: '请选择所在国家',
            chooseBirthday: '点击选择生日...',
            chooseRegion: '点击选择地区...',
            china: '中国',
            usa: '美国',
            tipUsername: '请输入用户名',
            tipEmail: '请输入邮箱',
            tipEmailNotPass: '邮箱格式不正确',
            tipPassword: '请输入密码',
            tipPwd2: '两次密码不一致',
            minPasswordLength: '密码长度最小6位'
          },
          lang_3: {
            basicInfo: '基礎信息',
            username: '用戶名',
            email: '郵箱',
            password: '密碼',
            passwordAgain: '再次輸入密碼',
            gender: '性別',
            male: '男',
            female: '女',
            birthday: '生日',
            country: '國家',
            serviceCondition: '服務條款',
            agree: '同意',
            register: '注冊',
            chooseDate: '選擇年月日',
            chooseCountry: '請選擇所在國家',
            chooseBirthday: '點擊選擇生日...',
            chooseRegion: '點擊選擇地區...',
            china: '中國',
            usa: '美國',
            tipUsername: '請輸入用戶名',
            tipEmail: '請輸入郵箱',
            tipEmailNotPass: '郵箱驗證不通過',
            tipPassword: '请输入密码',
            tipPwd2: '兩次密碼輸入不一致',
            minPasswordLength: '最小密碼長度為6位'
          }
        },
        currentLang: null
      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
      this.birthdayText = this.currentLang.chooseBirthday;
      this.nationText = this.currentLang.chooseRegion;
      this.nationOptions[0].text = this.currentLang.china;
      this.nationOptions[1].text = this.currentLang.usa;
    },
    mounted() {},
    methods: {
      getRadioVal() {
        //获取性别的选择
        console.log(this.radioVal);
      },
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      async regin() {
        //注册
        if (this.$refs.userCode.value == "") {
          Toast(this.currentLang.tipUsername);
          return;
        };
        if (this.$refs.email.value == "") {
          Toast(this.currentLang.tipEmail);
          return;
        };
        var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if (re.test(this.$refs.email.value)) {

        } else {
          Toast(this.currentLang.tipEmailNotPass);
          return false;
        }
        if (this.$refs.pwd.value == "") {
          Toast(this.currentLang.tipPassword);
          return;
        };
        if (this.$refs.pwd.length <= 5) {
          Toast(this.currentLang.minPasswordLength);
          return;
        };
        if (this.$refs.pwd.value != this.$refs.pwd2.value) {
          Toast(this.currentLang.tipPwd2);
          return;
        };
        
        var sex1 = ""; //1男2女
        if (this.sex == "2") {
        	sex1 = '女'; //1男2女
        } else {
        	sex1 = '男';
        }
        
        
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "adduser",
          tel: '',
          userpwd: this.$refs.pwd.value,
          yzm: '', //客户无短信，不需要短信验证码
          username: this.$refs.userCode.value, //目前注册没有名字，所以等于账号
          sex: sex1,
          kh: this.$refs.userCode.value, //会员的用户名
          birthday: this.birthdayText,
          address: this.nationText,
          email: this.$refs.email.value,
          headimg: 'http://www.anyaone.com/defauleHeadImg.jpg', //传一个默认头像
          fkfd: '', //这个会员对应的会员等级，不传值，后台自动用默认的等级
          sitenum: "18777777777",
          openid: "",
        };
        var result = await RequestApi.post(uri, param);
        // console.log(result);
        if (result.status == "1") {


         Toast("注册成功，请等待审核");
         //延时跳转 让顾客看清提示
         setTimeout(() => {
         	//需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
         	//this.$router.push("index/main");
         	this.$router.push("login");
         	//延迟时间：3秒
         }, 500)

          // //注册成功后自动登录
          // Cookies.set("kh", this.$refs.userCode.value);
          // Cookies.set("openid", this.openid);
          // Cookies.set("token", this.token); //每次登陆，token会变化，并且根据有效期会超时
          // Toast("注册成功");
          // //延时跳转 让顾客看清提示
          // setTimeout(() => {
          //   //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          //   this.$router.push("home");
          //   //延迟时间：3秒
          // }, 500)

        } else {
          Toast(result.rsg);
        }

        // that.userName = result.name;
        // that.userAddress = result.address;
      },
      details() {
        //查看服务协议
        this.$router.push({
          path: '/details',
          query: {}
        });
      },
      // 选项格式化函数
      formatter(type, value) {
        if (type === "year") {
          return `${value}`;
        } else if (type === "month") {
          return `${value}`;
        } else if (type === "day") {
          return `${value}`;
        } else if (type === "hour") {
          return `${value}`;
        } else if (type === "minute") {
          return `${value}`;
        } else if (type === "second") {
          return `${value}`;
        }
        return value;
      },
      selBirthDay(val) {
        let year = val.getFullYear();
        let month = val.getMonth() + 1;
        let day = val.getDate();
        let hour = val.getHours();
        let minute = val.getMinutes();
        if (month >= 1 && month <= 9) {
          month = `0${month}`;
        }
        if (day >= 1 && day <= 9) {
          day = `0${day}`;
        }
        if (hour >= 0 && hour <= 9) {
          hour = `0${hour}`;
        }
        if (minute >= 0 && minute <= 9) {
          minute = `0${minute}`;
        }
        this.birthdayText = `${year}-${month}-${day} ${hour}:${minute}`;
        this.birthdayActive = false;
        console.log(this.birthdayText);
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        // text-indent: 25px;
        color: #464646;
        text-indent: 90px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
